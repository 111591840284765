import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { Loader, ThemeProvider } from "@aws-amplify/ui-react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { saveAs } from "file-saver";
import { Container, Button, Autocomplete  } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import useErrors from "../../hooks/useError";

import MobileInput from "../common/MobileInput";
import CustomDialog from "../common/CustomDialog";

import occupations from "../../assets/occupations.json";
import industries from "../../assets/industry.json";
import industryCategories from "../../assets/industryCategory.json";

const { REACT_APP_KYC_BACKEND } = process.env;

export default ({
  IdFrontPage,
  IdBackPage,
  firstName,
  setFirstName,
  lastName,
  setlastName,
  address,
  setAddress,
  idNumber,
  setIdNumber,
  occupation,
  setOccupation,
  industry,
  setIndustry,
  salaryRange,
  setSalaryRange,
  industryCategory,
  setIndustryCategory,
  dob,
  setDob,
  countryCode,
  email,
  setEmail,
  mobileNumber,
  setMobileNumber,
  mobileCountryCode,
  setMobileCountryCode,
  backOneStep,
}) => {
  const { t } = useTranslation();
  const IdFrontTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/IdFront/singapore";
  const IdBackTextExtractURL = REACT_APP_KYC_BACKEND + "/textExtract/extractText/IdBack/singapore";
  const [loading, setLoading] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);
  const { setError, clearError, getError } = useErrors();

  const occupationOptions = Array.from(new Set(occupations));
  const [industryOptions, setIndustryOptions ]= useState(occupationOptions);
  // const [industryCategory, setIndustryCategory] = useState(null);

  useEffect(() => {
    const captureIdFrontDetails = async (image) => {
      const requestData = {
        img: image,
      };
      try {
        let textDetectFrontResponse = await axios.post(IdFrontTextExtractURL, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (textDetectFrontResponse.data.firstName !== "") {
          setFirstName(textDetectFrontResponse.data.firstName);
        }
        if (textDetectFrontResponse.data.lastName !== "") {
          setlastName(textDetectFrontResponse.data.lastName);
        }
        if (textDetectFrontResponse.data.IdNumber !== "") {
          setIdNumber(textDetectFrontResponse.data.IdNumber);
        }
        if (textDetectFrontResponse.data.dobTime !== "") {
          setDob(dayjs(textDetectFrontResponse.data.dobTime));
        }

        if (!textDetectFrontResponse.data.firstName && !textDetectFrontResponse.data.lastName && !textDetectFrontResponse.data.IdNumber && !textDetectFrontResponse.data.dobTime) {
          setDialogOpen(true);
        }
      } catch (error) {}
    };
    const captureIdBackDetails = async (image) => {
      const requestData = {
        img: image,
      };
      try {
        let textDetectBackResponse = await axios.post(IdBackTextExtractURL, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (textDetectBackResponse.data.address !== ", , ") {
          setAddress({ ["addressLine1"]: textDetectBackResponse.data.address });
        }
      } catch (error) {}
    };
    const fetchData = async () => {
      try {
        await Promise.all([captureIdFrontDetails(IdFrontPage), captureIdBackDetails(IdBackPage)]);
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // setError("mobileNumber", 'This field is required');
  }, []);

  const validateMobileNumber = (mobileNumber) => {
    const minLength = 8;
    const maxLength = 20;

    if (mobileNumber.length < minLength) {
      return t("Validation.mobileMinLength");
    }
    if (mobileNumber.length > maxLength) {
      return t("Validation.mobileMaxLength");
    }
    return "";
  };

  const validateField = (field, value) => {
    switch (field) {
      case "email":
        return !/\S+@\S+\.\S+/.test(value) ? t("Validation.email") : "";
      default:
        return !value ? t("Validation.required") : "";
    }
  };

  const handleFieldChange = (field, value) => {
    // console.log(value)
    // const e = validateMobileNumber(mobileNumber);
    // if(e && e !== '') {  setError("mobileNumber", e); }
    const errorMessage = validateField(field, value);
    if (errorMessage) {
      setError(field, errorMessage);
    } else {
      clearError(field);
    }

    switch (field) {
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleDobChange = (date) => {
    setDob(date);
  };
  const handleIdNumberChange = (event) => {
    setIdNumber(event.target.value);
  };
  const handleFirstnameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastnameChange = (event) => {
    setlastName(event.target.value);
  };
  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };
  // const handleMobileCountryCodeChange = (event) => {
  //   setMobileCountryCode(event.target.value);
  // };
  // const handleMobileNumberChange = (event) => {
  //   setMobileNumber(event.target.value);
  // };

  const handleAddressChange = (property, value) => {
    setAddress((prevAddress) => {
      const updatedAddress = {
        ...prevAddress,
        [property]: value,
      };

      if (countryCode === "sg") {
        updatedAddress.satisfied = updatedAddress.addressLine1 !== "" && updatedAddress.postal !== "";
      } else if (countryCode === "us") {
        updatedAddress.satisfied = updatedAddress.addressLine1 !== "" && updatedAddress.state !== "" && updatedAddress.postal !== "" && updatedAddress.city !== "";
      } else {
        updatedAddress.satisfied = updatedAddress.addressLine1 !== "";
      }

      return updatedAddress;
    });
  };

  const handleIndustryChange = (value) => {
    if(typeof value === 'string') return;
    setIndustry(value);
  };

  const onDialogClose = (event, reason) => {
    if (reason == "backdropClick" || reason == "escapeKeyDown") return;
    setDialogOpen(false);
  };
  const onDialogAccept = (event) => {
    //accept reupload
    setDialogOpen(false);
    backOneStep();
  };
  const onDialogReject = (event) => {
    //reject reupload
    setDialogOpen(false);
  };
  function getAddressForm() {
    switch (countryCode) {
      case "sg":
        return (
          <div>
            <div className="mb-4 flex flex-col sm:flex-row gap-2">
              <div className="w-full sm:w-1/2">
                <TextField
                  id="outlined-controlled"
                  label={t("AnalyzeIDForm.addressLine1")}
                  variant="outlined"
                  fullWidth
                  value={address.addressLine1}
                  onChange={(event) => handleAddressChange("addressLine1", event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-full"
                />
              </div>
              <div className="w-full sm:w-1/2">
                <TextField
                  id="outlined-controlled"
                  label={t("AnalyzeIDForm.addressLine2")}
                  variant="outlined"
                  fullWidth
                  value={address.addressLine2}
                  onChange={(event) => handleAddressChange("addressLine2", event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="w-full"
                />
              </div>
            </div>
            <div className="mb-4 flex flex-col sm:flex-row gap-2">
              <div className="w-full sm:w-1/2">
                <TextField
                    id="outlined-controlled"
                    label={t("AnalyzeIDForm.postal")}
                    variant="outlined"
                    fullWidth
                    value={address.postal}
                    onChange={(event) => handleAddressChange("postal", event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              </div>
            </div>
          </div>
        );
      case "us":
        return (
          <div>
            <TextField
              id="outlined-controlled-addressLine1"
              label={t("AnalyzeIDForm.addressLine1")}
              variant="outlined"
              fullWidth
              value={address.addressLine1}
              onChange={(event) => handleAddressChange("addressLine1", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-controlled"
              label={t("AnalyzeIDForm.addressLine2")}
              variant="outlined"
              fullWidth
              value={address.addressLine2}
              onChange={(event) => handleAddressChange("addressLine2", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-controlled-city"
              label={t("AnalyzeIDForm.city")}
              variant="outlined"
              fullWidth
              value={address.city}
              onChange={(event) => handleAddressChange("city", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-controlled-state"
              label={t("AnalyzeIDForm.state")}
              variant="outlined"
              fullWidth
              value={address.state}
              onChange={(event) => handleAddressChange("state", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-controlled-postal"
              label={t("AnalyzeIDForm.postal")}
              variant="outlined"
              fullWidth
              value={address.postal}
              onChange={(event) => handleAddressChange("postal", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        );
      default:
        return (
          <div>
            <TextField
              id="outlined-controlled-addressLine1"
              label={t("AnalyzeIDForm.addressLine1")}
              variant="outlined"
              fullWidth
              value={address.addressLine1}
              onChange={(event) => handleAddressChange("addressLine1", event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        );
    }
  }

  function getOccupationForm() {
    return (
      <div className="mt-4">
        <hr className="my-4"></hr>
        <div class="ml-1">
          <Typography
            variant="h1"
            component="span"
            sx={{
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0px",
              textAlign: "left",
              color: "black",
              mb: 0,
              ml: 0,
              fontFamily: "system-ui",
            }}
            >{t("AnalyzeIDForm.subContent")}</Typography>
        </div>
        <div className="pt-4 mb-4 flex flex-col sm:flex-row gap-2">
          <div className="w-full sm:w-1/2">
            <Autocomplete
              value={industryCategory || ''}
              onChange={(event, newValue) => {
                handleFieldChange("industryCategory", newValue);
                setIndustryCategory(newValue || '');
                const nv= industries.filter((e)=>e.category === newValue);
                setIndustryOptions(nv);
                // setIndustry(nv[1]);
              }}
              inputValue={industryCategory || ''}
              onInputChange={(event, newInputValue) => {
                setIndustryCategory(newInputValue || '');
              }}
              id="industryCategory"
              options={industryCategories}
              className="w-full"
              variant="outlined"
              renderInput={(params) => <TextField  error={getError("industryCategory")} helperText={getError("industryCategory")}  {...params} label={t("AnalyzeIDForm.industryCategory")} />}
            />
          </div>

          {
            false && 
            <div className="w-full sm:w-1/2">
              <Autocomplete
                value={industry?.name || ''}
                onChange={(event, newValue) => {
                  handleFieldChange("industry", newValue);
                  handleIndustryChange(newValue);
                }}
                inputValue={industry?.name || ''}
                onInputChange={(event, newInputValue) => {
                  handleIndustryChange(newInputValue);
                }}
                id="industry"
                getOptionLabel={(option) => option?.name || ''}
                options={industryOptions}
                // sx={{ width: 300 }}
                variant="outlined"
                fullWidth
                renderInput={(params) => <TextField error={getError("industry")} helperText={getError("industry")}  {...params} label={t("AnalyzeIDForm.industry")} />}
              />
            </div>
          }
          {
            <div className="w-full sm:w-1/2">
              <Autocomplete
                value={salaryRange || ''}
                onChange={(event, newValue) => {
                  handleFieldChange("salaryRange", newValue);
                  setSalaryRange(newValue || '');
                }}
                inputValue={salaryRange || ''}
                onInputChange={(event, newInputValue) => {
                  setSalaryRange(newInputValue || '');
                }}
                id="salaryRange"
                options={['< $2,000','$2,001 - $5,000', '$5,001 - $10,000', '> $10,000']}
                className="w-full"
                variant="outlined"
                renderInput={(params) => <TextField  error={getError("salaryRange")} helperText={getError("salaryRange")}  {...params} label={t("AnalyzeIDForm.salaryRange")} />}
              />
            </div>
          }

        </div>
        <div className="mb-4 flex flex-col sm:flex-row gap-2">
              <div  className="w-full sm:w-1/2">
                <Autocomplete
                  value={occupation || ''}
                  onChange={(event, newValue) => {
                    setOccupation(newValue || '');
                    handleFieldChange("occupation", newValue);
                  }}
                  inputValue={occupation || ''}
                  onInputChange={(event, newInputValue) => {
                    setOccupation(newInputValue || '');
                  }}
                  id="occupation"
                  options={occupationOptions}
                  // sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} error={getError("occupation")}
                  helperText={getError("occupation")} label={t("AnalyzeIDForm.occupation")} />}
                />
              </div>
        </div>


        {/* {JSON.stringify(industry)} */}
      </div>
    );
  }
  return (
    <Suspense fallback="...loading">
      <div>
        {!loading && (
          <Container
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              component="span"
              sx={{
                fontSize: "22px",
                fontWeight: 550,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "black",
                mt: 0,
                mb: 2,
                ml: 0,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzeIDForm.confirmInformation")}
            </Typography>
            <Typography
              variant="h1"
              component="span"
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left",
                color: "black",
                mb: 1,
                ml: 0,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzeIDForm.content")}
            </Typography>
            <Box
              component="form"
              sx={{
                // "& .MuiTextField-root": { m: 1, width: "100%", borderBottom: 0 },
                mt: 1,
              }}
              noValidate
            >
              <div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeIDForm.first")}
                      value={firstName}
                      variant="outlined"
                      onChange={handleFirstnameChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      fullWidth
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeIDForm.last")}
                      value={lastName}
                      variant="outlined"
                      onChange={handleLastnameChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeIDForm.number")}
                      value={idNumber}
                      variant="outlined"
                      onChange={handleIdNumberChange} // Attach the onChange event handler
                      InputLabelProps={{
                        shrink: true, // Keep the label always shrunk above the input
                      }}
                      fullWidth
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                      <DatePicker
                        label={t("AnalyzeIDForm.dob")}
                        value={dob}
                        onChange={handleDobChange}
                        format="DD/MM/YYYY" // Customize the date format
                        mask="__/__/____" // Add a mask for better input control (optional)
                        fullWidth
                        renderInput={(params) => <TextField {...params} variant="filled" fullWidth />}
                        className="w-full"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="mb-4 flex flex-col sm:flex-row gap-2">
                  <div className="w-full sm:w-1/2">
                    <TextField
                      id="outlined-controlled"
                      shrink={false}
                      label={t("AnalyzeForm.email")}
                      value={email}
                      variant="outlined"
                      onChange={(e) => handleFieldChange("email", e.target.value)} // Attach the onChange event handler
                      InputLabelProps={{ shrink: true }} // Keep the label always shrunk above the input
                      error={getError("email")}
                      helperText={getError("email")}
                      className="w-full"
                    />
                  </div>
                  <div className="w-full sm:w-1/2">
                    <MobileInput
                      mobileNumber={mobileNumber}
                      setMobileNumber={setMobileNumber}
                      mobileCountryCode={mobileCountryCode}
                      setMobileCountryCode={setMobileCountryCode}
                      //onChange={handleMobileNumberCHange} // Attach the onChange event handler
                    />
                  </div>

                </div>

                {getAddressForm()}
                {getOccupationForm()}
                <Box sx={{ width: "70ch" }}>
                  {/* <TextField
                        id="outlined-controlled"
                        shrink={false}
                        label={t('AnalyzeIDForm.address')}
                        value={address}
                        variant="outlined"
                        onChange={handleAddressChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /> */}
                </Box>
              </div>
            </Box>
          </Container>
        )}
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100px" // Set the desired height (adjust as needed)
            marginTop="170px"
            marginBottom="170px"
          >
            <CircularProgress size={100} color="primary" /> {/* Adjust the size and color */}
          </Box>
        )}

        <CustomDialog
          onClose={onDialogClose}
          open={dialogOpen}
          content={
            <Typography
              variant="h1"
              component="div"
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "center",
                color: "black",
                mb: 2,
                fontFamily: "system-ui",
              }}
            >
              {t("AnalyzeIDForm.ocrRetryMsg")}
              <br />
              {t("AnalyzeIDForm.ocrRetryConfirmation")}
            </Typography>
          }
          footer={
            <>
              <Button
                variant="contained"
                color="primary"
                sx={{ minWidth: "100px", fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none" }}
                onClick={onDialogAccept}
              >
                {t("general.yes")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ minWidth: "100px", fontFamily: "system-ui", borderRadius: "20px", fontSize: "17px", fontWeight: 500, textTransform: "none" }}
                onClick={onDialogReject}
              >
                {t("general.no")}
              </Button>
            </>
          }
        />
      </div>
    </Suspense>
  );
};
